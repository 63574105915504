<template>
    <div class="chart">
        <div class="chart-title">
            <img src="@/assets/aggroTrace/down.png" alt="">
            <span>每亩农资投入情况</span>
        </div>
        <div class="echarts" id="echarts2"></div>
    </div>
</template>
<script>
import * as echarts from "echarts";
import {  groupBy } from './commom.js'
export default{
    data(){
        return{
            option:{
                color: ['#fda205', '#62d6fe'],
                title: {
                    show:false
                },
                grid:{
                    left: '10%',
                    right: '10%',
                    top:35,
                    bottom: 30
                },
                legend: {
                    show:true,
                    icon: 'rect',
                    itemWidth:10,
                    itemHeight:10,
                    textStyle:{
                        color:'#fff'
                    }
                },
                xAxis: {
                    data: ['Mon', 'Tue', 'Wed', 'Thu'],
                    axisLabel: {
                      color: '#fff'
                    },
                    axisTick: {
                      show: false
                    },
                    axisLine: {
                     show: true,
                     lineStyle:{
                        color: 'rgba(28,93,100,0.9)',
                      }
                    },
                    z: 10
                },
                yAxis: {
                    axisLine: {
                      show: false,
                    },
                    axisLabel: {
                       color: '#fff'
                    },
                    splitLine:{
                        lineStyle:{
                            color: 'rgba(255,255,255,0.3)',
                            type: 'dashed'
                        }
                    }
                },
                series: [
                    {
                        name: '2022',
                        type: 'line',
                        data: [120, 200, 150, 80, 70, 110, 130]
                    },
                    {
                        name: '2023',
                        type: 'line',
                        data: [110, 80, 100, 80, 70, 70, 50]
                    }
                ]
            }
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            const myChart = echarts.init(document.getElementById('echarts2'));
            this.$get('inputPurchase/pageUse',{
                page: 1,
                size: 100
            }).then((res) => {
                if (res.data.state == 'success') {
                    // mock数据
                //    const mockData = [{
                //     useTime: '2024-5-25 6:20:30',
                //     usePurpose: "驱虫1",
                //     useNumber: 105
                //    },{
                //     useTime: '2024-5-25 6:20:30',
                //     usePurpose: "驱虫2",
                //     useNumber: 35
                //    },{
                //     useTime: '2024-5-25 6:20:30',
                //     usePurpose: "驱虫3",
                //     useNumber: 65
                //    },{
                //     useTime: '2024-5-25 6:20:30',
                //     usePurpose: "驱虫4",
                //     useNumber: 75
                //    },{
                //     useTime: '2023-5-25 6:20:30',
                //     usePurpose: "驱虫1",
                //     useNumber: 40
                //    },{
                //     useTime: '2023-5-25 6:20:30',
                //     usePurpose: "驱虫2",
                //     useNumber: 68
                //    },{
                //     useTime: '2023-5-25 6:20:30',
                //     usePurpose: "驱虫3",
                //     useNumber: 89
                //    },{
                //     useTime: '2023-5-25 6:20:30',
                //     usePurpose: "驱虫4",
                //     useNumber: 124
                //    }]
                   const data = res.data.datas.map(v=>{
                    return {
                        ...v,
                        useTime: v.useTime.substring(0, 4)
                    }
                   })
                   const lastData  = groupBy(data, 'useTime');
                   this.option.series = Object.keys(lastData).map(v=>{
                        this.option.xAxis.data = lastData[v].map(m=>m.usePurpose)
                        return {
                            name: v,
                            type: 'line',
                            data: lastData[v].map(m=>m.useNumber)
                        }
                   })
                   myChart.setOption(this.option);
                }
            });
            
        }
    },
}
</script>
<style lang="scss" scoped>
.chart{
    width: 50%;
    height: 100%;
    &-title{
        height: 40px;
        background-color: rgba(20,103,105,0.4);
        margin: 10px 10%;
        display: flex;
        color: #fff;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        img{
            width: 35px;
            margin-right: 10px;
        }
    }
}
.echarts{
    width: 100%;
    height: calc(100% - 50px);
}
</style>